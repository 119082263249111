import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/worker/code/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Last night, Arlyn, PayPerks CEO, joined the Center for Financial Services Innovation (CFSI) and JPMorgan Chase & Co. (JPMC) for the launch of their new `}<a parentName="p" {...{
        "href": "http://cfsinnovationlabs.com/financialsolutionslab/"
      }}>{`Financial Solutions Lab`}</a>{`: a joint initiative to identify and enhance tech-enabled innovations that address consumer needs.`}</p>
    <div {...{
      "className": "mb-3"
    }}><span parentName="div" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "491px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "72%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAQCAwX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAABXnm2xYLCf//EABoQAAMBAAMAAAAAAAAAAAAAAAABAxECEjH/2gAIAQEAAQUCjRopy7LcJ+tDZ//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8BTwp//8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPwFrSH//xAAZEAACAwEAAAAAAAAAAAAAAAAAARAhMRH/2gAIAQEABj8C2KfDSnH/xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhQTFh/9oACAEBAAE/ISatTyHVyXwo+ReRCxh02WM//9oADAMBAAIAAwAAABAnD//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QA//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QB//EABsQAQEAAgMBAAAAAAAAAAAAAAERAFEhMUHh/9oACAEBAAE/EFdj+uPmPN1QMqah0jF5L+x81h6IUmrlVQuf/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/0a1331b0d767d7be84c967973413696e/7f61c/arlyn-speaking-at-financial-solutions-lab_small.webp 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/0a1331b0d767d7be84c967973413696e/881c7/arlyn-speaking-at-financial-solutions-lab_small.webp 491w"],
            "sizes": "(max-width: 491px) 100vw, 491px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["https://cdn.payperks.com/payperks-corporate/1.16.1/static/0a1331b0d767d7be84c967973413696e/066f9/arlyn-speaking-at-financial-solutions-lab_small.jpg 400w", "https://cdn.payperks.com/payperks-corporate/1.16.1/static/0a1331b0d767d7be84c967973413696e/aa486/arlyn-speaking-at-financial-solutions-lab_small.jpg 491w"],
            "sizes": "(max-width: 491px) 100vw, 491px",
            "type": "image/jpeg"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "https://cdn.payperks.com/payperks-corporate/1.16.1/static/0a1331b0d767d7be84c967973413696e/aa486/arlyn-speaking-at-financial-solutions-lab_small.jpg",
            "alt": "arlyn speaking at financial solutions lab small",
            "title": "arlyn speaking at financial solutions lab small",
            "loading": "lazy",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></div>
    <p>{`Joined by co-panelists (from left to right, below) Max Gasner (CEO, `}<a parentName="p" {...{
        "href": "http://www.onefinancialholdings.com/"
      }}>{`One Financial Holdings`}</a>{`), Tom Mikluch (Executive Director, Technology Business Development, JPMC) and Josh Wright (Executive Director, `}<a parentName="p" {...{
        "href": "http://www.ideas42.org/"
      }}>{`Ideas42`}</a>{`), Arlyn discussed how PayPerks partners with financial institutions and governments to increase its impact on consumer financial health.`}</p>
    <p>{`You can sign up `}<a parentName="p" {...{
        "href": "http://cfsinnovationlabs.com/financialsolutionslab/"
      }}>{`here`}</a>{` to for Financial Solutions Lab updates.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      